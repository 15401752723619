<template>
  <Item
    :item="item"
    :parent="parent"
    class="flex space-x-4 social-links"
    :class="{
      'justify-start': parent.props.align === 'left',
      'justify-center': parent.props.align === 'center',
      'justify-end': parent.props.align === 'right',
    }"
  >
    <a
      v-for="({ url, icon }, idx) in item.settings.items"
      :key="idx"
      :href="isAdminMode ? '#' : url"
      rel="noreferrer noopener"
    >
      <component :is="icon" v-if="icon" class="w-6 h-6" />
      <span v-else>{{ getHostName(url) }}</span>
    </a>
  </Item>
</template>

<script>
import { get } from 'vuex-pathify'
import socialIconsMixin from '@/mixins/socialIcons'
import itemMixin from '@/mixins/item'

export default {
  name: 'SocialLinks',

  components: {
    Item: () => import('@/components/Item'),
  },

  mixins: [itemMixin, socialIconsMixin],

  computed: {
    isAdminMode: get('isAdminMode'),
  },

  methods: {
    getHostName(url) {
      return new URL(url).hostname
    },
  },
}
</script>

<style lang="postcss">
.social-links {
  & svg {
    & path {
      fill: var(--contrastColor);
    }
  }
}
</style>
