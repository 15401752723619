var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Item',{staticClass:"flex space-x-4 social-links",class:{
    'justify-start': _vm.parent.props.align === 'left',
    'justify-center': _vm.parent.props.align === 'center',
    'justify-end': _vm.parent.props.align === 'right',
  },attrs:{"item":_vm.item,"parent":_vm.parent}},_vm._l((_vm.item.settings.items),function(ref,idx){
  var url = ref.url;
  var icon = ref.icon;
return _c('a',{key:idx,attrs:{"href":_vm.isAdminMode ? '#' : url,"rel":"noreferrer noopener"}},[(icon)?_c(icon,{tag:"component",staticClass:"w-6 h-6"}):_c('span',[_vm._v(_vm._s(_vm.getHostName(url)))])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }