export default {
  components: {
    Airbnb: () => import('simple-icons/icons/airbnb.svg?inline'),
    Codepen: () => import('simple-icons/icons/codepen.svg?inline'),
    Buymeacoffee: () => import('simple-icons/icons/buymeacoffee.svg?inline'),
    Deviantart: () => import('simple-icons/icons/deviantart.svg?inline'),
    Dribbble: () => import('simple-icons/icons/dribbble.svg?inline'),
    Discord: () => import('simple-icons/icons/discord.svg?inline'),
    Facebook: () => import('simple-icons/icons/facebook.svg?inline'),
    Flickr: () => import('simple-icons/icons/flickr.svg?inline'),
    Github: () => import('simple-icons/icons/github.svg?inline'),
    Goodreads: () => import('simple-icons/icons/goodreads.svg?inline'),
    Instagram: () => import('simple-icons/icons/instagram.svg?inline'),
    Linkedin: () => import('simple-icons/icons/linkedin.svg?inline'),
    Medium: () => import('simple-icons/icons/medium.svg?inline'),
    Patreon: () => import('simple-icons/icons/patreon.svg?inline'),
    Paypal: () => import('simple-icons/icons/paypal.svg?inline'),
    Pinterest: () => import('simple-icons/icons/pinterest.svg?inline'),
    Quora: () => import('simple-icons/icons/quora.svg?inline'),
    Reddit: () => import('simple-icons/icons/reddit.svg?inline'),
    Snapchat: () => import('simple-icons/icons/snapchat.svg?inline'),
    Soundcloud: () => import('simple-icons/icons/soundcloud.svg?inline'),
    Spotify: () => import('simple-icons/icons/spotify.svg?inline'),
    Telegram: () => import('simple-icons/icons/telegram.svg?inline'),
    Tiktok: () => import('simple-icons/icons/tiktok.svg?inline'),
    Tumblr: () => import('simple-icons/icons/tumblr.svg?inline'),
    Twitch: () => import('simple-icons/icons/twitch.svg?inline'),
    Twitter: () => import('simple-icons/icons/twitter.svg?inline'),
    Vimeo: () => import('simple-icons/icons/vimeo.svg?inline'),
    Youtube: () => import('simple-icons/icons/youtube.svg?inline'),
  },

  data: () => ({
    icons: [
      'airbnb',
      'buymeacoffee',
      'codepen',
      'deviantart',
      'dribbble',
      'discord',
      'facebook',
      'flickr',
      'github',
      'goodreads',
      'instagram',
      'linkedin',
      'medium',
      'patreon',
      'paypal',
      'pinterest',
      'quora',
      'reddit',
      'snapchat',
      'soundcloud',
      'spotify',
      'telegram',
      'tiktok',
      'tumblr',
      'twitch',
      'twitter',
      'vimeo',
      'youtube',
    ],
  }),
}
